.lifestyle-assessment-layout {
  display: flex;
  flex-wrap: wrap;
}

.lifestyle-assessment-layout__label {
  color: var(--gray-scale-2);
}

.lifestyle-assessment-layout__content {
  display: flex;
  flex: 1;
  padding-right: 100px;
}