.consent-form-pending-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 25%;

  img {
    width: 400px;
    height: 400px;
  }
}