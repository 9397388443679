.CgmGoalTooltipComponent {
  padding: 16px;

  &__title {
    color: var(--Grayscale-1, #2D3238);
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }

  &__cgm-unit {
    font-size: 10px;
    align-content: center;
  }

  &__cgm-range {
    font-size: 12px;
    color: #6B7178;
    background-color: #F4F6F8;
    padding: 5px;
    margin-bottom: 12px;
    border-radius: 3px;
    border: 1px solid var(--Grayscale-4, #E7EAED);
    background: var(--Grayscale-5, #F4F6F8);
    justify-content: space-evenly;
  }

  &__custom-table {
    border: 1px solid var(--Grayscale-3, #B5B9BE);
    border-radius: 5px;
  }

  &__range-box {
    text-align: center;
    font-size: 12px;
  }

  // Category cell
  &__custom-row:first-child td:first-child {
    border-radius: 7px 0 0 7px; // Combine border-radius into shorthand
    border-right: 1px solid var(--Grayscale-3, #B5B9BE);
    font-weight: bold;
    background-color: #E9F1FA;
  }

  // 1st and 2nd rows
  &__custom-row:first-child td:nth-child(even),
  &__custom-row:nth-child(n+2) td:nth-child(odd) {
    background-color: #F4F6F8;
  }

  &__custom-row:first-child td:nth-child(n+2) {
    border-bottom: 1px solid var(--Grayscale-3, #B5B9BE);
  }

  .ant-table-wrapper {
    margin-bottom: 0;
  }

  // Hover effects
  .ant-table-tbody > tr:first-child:hover > td:first-child {
    background-color: #E9F1FA;
  }

  .ant-table-tbody > tr:first-child:hover > td:nth-child(even),
  .ant-table-tbody > tr:nth-child(n+2):hover > td:nth-child(odd) {
    background-color: #F4F6F8;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: white;
  }

  .inline-flex {
    gap: 2px;
  }
}
