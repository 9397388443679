.CgmGoalFormComponent {
  &:not(&__input-box) {
    padding-left: 5px;
    padding-right: 5px;
    width: 120px;

    .ant-form-item-has-error .ant-form-item-explain-error {
      color: var(--secondary-red);
      font-size: 14px;
      max-width: 120px;
      word-wrap: break-word;
      white-space: normal;
    }
  }

  &__input-box {
    width: 120px;
    .ant-input-group-addon {
      padding: 0 6px;
    }
  }

  &__input-number {
    width: 80px;

    .ant-input-group .ant-input { 
      text-align: center; 
    }
  }

  &__status {
    margin-left: auto;
    margin-top: 3px;
  }

  &__reset-bottom {
    background: transparent !important;
  }

  &__reset-button-icon {
    transition: color 0.2s ease-in-out;
  }
  
  &__reset-button-icon:hover {
    color: #107EFF;
  }

  .ant-input-group-addon {
    padding: 0 6px;
  }

  .ant-form-item-explain {
    font-size: 12px;
    white-space: nowrap;
  }
}
