.smoke-drink__freq {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;

  .ant-input-number {
    width: 70px;
  }

  .ant-select {
    min-width: 100px;
  }

  .ant-input-number-group-wrapper .ant-form-item-control {
    height: 30px;
  }

  .ant-input-number-group-wrapper .ant-input-number-group-addon {
    background-color: #FFFFFF;
  }

  .ant-input-number-group-addon .ant-select-arrow {
    right: 4px;
  }
}

.smoke-drink__freq__text {
  margin-top: 16px;
}