.portal-layout {
  --layout-header-height: calc(55px + var(--preview-env-banner-height, 0px));
  --layout-side-menu-width: 72px;

  .portal-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--layout-header-height);
    background-color: white;
    z-index: 3;
  }

  .portal-body {
    display: flex;
    width: 100vw;
  }

  .portal-side-menu {
    position: fixed;
    left: 0;
    top: var(--layout-header-height);
    overflow: auto;
    z-index: 2;
  }

  .portal-content {
    width: calc(100% - var(--layout-side-menu-width));
    height: calc(100vh - var(--layout-header-height));
    margin-top: var(--layout-header-height);
    margin-left: var(--layout-side-menu-width);
    overflow-y: auto;
  }
}