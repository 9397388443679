.CgmBarChartImageComponent {
  &__label {
    color: var(--Grayscale-2, #6B7178);
    text-align: center;
    font-size: 12px;
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
  }

  &__bar-chart {
    display: grid;
    gap: max(1px);
    margin-right: 10px;
    max-height: 110px;
  }
  
  &__tar-level-2-bar{
    background-color: #CE7A35;
    width: 60px;
  }

  &__tar-level-1-bar{
    background-color: #DDA73B;
    width: 60px;
  }

  &__tir-bar{
    background-color: #53A15B;
    width: 60px;
  }

  &__tbr-level-1-bar{
    background-color: #BA282E;
    width: 60px;
  }

  &__tbr-level-2-bar{
    background-color: #742F32;
    width: 60px;
  }
}