.consent-form-status-bar {
  &.signed-consent {
    background-color: #E9FAF0;
  }
  &.updated-consent {
    background-color: var(--red-tint);
  }
  &.pending-consent {
    background-color: var(--orange-tint);
  }
}