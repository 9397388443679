.CgmReportPreviewComponent {
  max-width: 816px;
  width: 816px;

  .ant-divider {
    border-top-color: var(--gray-scale-3);
  }

  .cgm-statistics .cgm-stats-value {
    text-align: right;
  }

  .CgmVitalSummaryComponent .ant-card-bordered {
    border: none;
  }

  .CgmVitalSummaryComponent .ant-card-body {
    padding: 0;
  }

  .CgmStatisticsComponent {
    margin-bottom: 16px;
  }

  .cgm-tooltip {
    display: none;
  }
}
