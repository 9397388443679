.OldAppVersionNoteComponent {
  margin-bottom: 10px;

  &__banner {
    font-weight: 400;
    margin-left: 5px;
    text-align: justify;
    display: flex;
  }

  &__note {
    align-content: space-around;
  }
}
