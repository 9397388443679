.old-intake-table-component {

  .ant-table-container {
    border: 1px solid #E7EAED;
    border-radius: var(--secondary-radius);
  }

  .ant-table table {
    width: 100%;
    text-align: left;
    color: var(--gray);
  }

  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-tbody>tr>td {
    border-bottom: 0;
    border-top: 1px solid #E7EAED;
  }

  .ant-table-thead>tr>th {
    background: #F0F0F0;
    border-bottom: 0;
    color: var(--secondary-gray);
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .default {
    color: var(--gray);
  }

  .second {
    color: var(--secondary-gray);
  }

}