.icon-text-box {
  padding: 14px 25px;
  color: #172B4D;

  // background fill
  &.primary-blue-tint {
    background-color: var(--blue-tint, #E9F1FA);
    border-color: var(--ant-blue, #107EFF);
    
    .icon-text-box__icon {
      color: var(--ant-blue, #107EFF);
    }
  }

  &.secondary-yellow {
    background-color: var(--light-yellow, #FFF9DF);
    border-color: var(--secondary-yellow, #F2C81A);

    .icon-text-box__icon {
      color: var(--secondary-yellow, #F2C81A);
    }
  }
}