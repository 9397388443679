.print-lifestyle-assessment__info-list {
  .print-care-plan-item-content > div {
    display: ruby !important;
    gap: 5px;

    // items
    & > div,
    & > span {
      margin-right: 5px;
    }
  }
}