@use 'antd/dist/antd';
@use './antdCustomized.scss';
@use './styles/tab.scss';
@import './styles/form.scss';
@import './styles/commonAntd.scss';

:root {
  --general-visit: #6236ff;
  --initial-visit: #36c5cc;
  --follow-up-visit: #4278c3;
  --additional-visit: #ee7c81;
  --tech-visit: #ffb310;
  --mnt-visit: #83c858;
  --tech-onboarding-visit: #cf51ab;
  --uc-blue: #3177C9;
  --uc-light-blue: #5a92d4;
  --uc-blue-secondary: #E9F1FA;
  --blue-tint: #E9F1FA;
  --blue: #2aa1ff;
  --dark-blue: #00479A;
  --primary-blue-6: #1890FF;
  --ant-blue: #107EFF;
  --dark-red: #CB1C1C;
  --secondary-red: #DA6453;
  --red-tint: #FEE9E6;
  --secondary-orange: #EF973B;
  --orange-tint: #FFF4E8;
  --due-date-warning: #FF982D;
  --background-gray: #F4F6F8;
  --background-gray-a03: rgba(244, 246, 248, 0.3);
  --background-even: #fafafa;
  --gray: #2D3238;
  --secondary-gray: #6B7178;
  --text-gray-primary: #6B7178;
  --text-gray: #595959;
  --text-darker-gray: #333333;
  --bg-gray: #484848;
  --bg-light-gray: #F5F5F5;
  --border-gray: #d9d9d9;
  --light-gray: #E7EAED;
  --secondary-yellow: #F2C81A;
  --yellow-tint: #FFF9DF;
  --light-yellow: #FFF9DF;
  --orange: #FF6B00;
  --light-orange: #FDF4EA;
  --default-radius: 4px;
  --secondary-radius: 8px;
  --middle-table-radius: 18px;
  --small-table-radius: 9px;
  --green: #00875A;
  --green-tint: #E6F7F2;
  --primary-green: #00C8BC;
  --secondary-green: #3BA767;
  --tertiary-green: #E5F9F8;
  --gray-scale-1: #2D3238;
  --gray-scale-2: #6B7178;
  --gray-scale-3: #B5B9BE;
  --gray-scale-4: #E7EAED;
  --gray-scale-5: #F4F6F8;
  --gray-scale-6: #FAFBFC;
  --gray-scale-7: #FAFAFA;
  --gray-scale-8: #FBFBFB;
  --common-padding: 35px 35px 40px 31px;
  --common-card-padding: 20px 16px 20px 26px;
  --confirmation-modal-width: 378px;
  --neutral: #8C8C8C;
  --highlight-blue: #1890ff;
}

.highcharts-credits {
  opacity: 0;
}

.App {
  width: 100%;
  height: 100%;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.white-card {
  border: 1px solid var(--border-gray);
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: var(--default-radius);
}

.layout-header-height {
  --header-height: 55px;
  height: var(--header-height);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.text-align-center,
.ta-c {
  text-align: center;
}

.text-align-justify,
.ta-j {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

button.uc-btn.ant-btn-primary {
  background: #4278c3;
  font-family: Avenir-Roman, Helvetica Neue, sans-serif;
  font-size: 16px;
  height: 38px;
  padding: 0 14px;
  border-radius: 5px;
  color: #fff;
  line-height: 36px;
  font-weight: 200;
  box-shadow: none;
}

.text-gray-scale-1 {
  color: var(--gray-scale-1);
}

.text-gray-scale-2 {
  color: var(--gray-scale-2);
}

.text-gray-scale-3 {
  color: var(--gray-scale-3);
}

.text-gray-scale-4 {
  color: var(--gray-scale-4);
}

.text-gray-scale-5 {
  color: var(--gray-scale-5);
}

.text-link {
  color: #1890FF;
}

.text-white {
  color: #fff;
}

.text-green {
  color: var(--secondary-green);
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.display-none {
  display: none;
}

.underline,
.underlined {
  text-decoration: underline;

  span {
    text-decoration: underline;
  }
}

.pointer {
  cursor: pointer;
}

.no-pointer {
  cursor: default;
}

.fs8 {
  font-size: 8px;
}

.fs10 {
  font-size: 10px;
}

.fs-small,
.fs12 {
  font-size: 12px;
}

.fs12i {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px;
}

.fs-normal,
.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs-large,
.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs-xlarge,
.fs20 {
  font-size: 20px;
}

.fs1 {
  font-size: 0.9em;
}

.fs2 {
  font-size: 1.1em;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.flex {
  display: flex;
  gap: 5px;
}

.flex-i {
  display: flex !important;
  gap: 5px;
}

.inline-flex {
  display: inline-flex;
  gap: 5px;
}

.inline-flex-i {
  display: inline-flex !important;
  gap: 5px;
}

.initial {
  display: initial;
}

.fr-reverse {
  flex-direction: row-reverse;
}

.fc-reverse {
  flex-direction: column-reverse;
}

.gap0 {
  gap: 0px;
}

.gap1 {
  gap: 5px;
}

.gap2 {
  gap: 10px;
}

.gap3 {
  gap: 20px;
}

.gap4 {
  gap: 30px;
}

.gap5 {
  gap: 40px;
}

.f1 {
  flex: 1;
}

.fd-c {
  flex-direction: column;
}

.fd-c.reverse {
  flex-direction: column-reverse;
}

.fd-r.reverse {
  flex-direction: row-reverse;
}

.flex-start,
.jc-s {
  justify-content: flex-start;
}

.flex-end,
.jc-e {
  justify-content: flex-end;
}

.jc-sb {
  justify-content: space-between;
}

.jc-se {
  justify-content: space-evenly;
}

.jc-c {
  justify-content: center;
}

.ai-c {
  align-items: center;
}

.ai-s {
  align-items: flex-start;
}

.ai-e {
  align-items: flex-end;
}

.ai-bl {
  align-items: baseline;
}

.flex-center,
.flex-c {
  display: flex;
  justify-content: center;
}

.flex-center-center,
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrap,
.f-w {
  flex-wrap: wrap;
}

.text-df-gray {
  color: var(--gray-scale-1);
}

.text-gray {
  color: var(--text-gray);
}

.text-gray-primary {
  color: var(--text-gray-primary);
}

.text-secondary-gray,
.secondary-gray {
  color: var(--secondary-gray);
}

.text-red {
  color: var(--secondary-red);
}

.text-darker-gray {
  color: var(--text-darker-gray);
}

.text-blue {
  color: var(--ant-blue);
}

.text-lower {
  text-transform: lowercase;
}

.text-upper {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-capitalize-first {
  &:first-letter {
    text-transform: capitalize;
  }
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-gray {
  background-color: var(--bg-gray);
  color: white;
}

.bg-black {
  background-color: black;
  color: white;
}

.bg-transparent {
  background-color: transparent;
}

@mixin bg-gray-list {
  @for $i from 1 through 8 {
    .bg-gray#{$i} {
      background-color: var(--gray-scale-#{$i});
    }
  }
}

@include bg-gray-list;

.bg-textarea {
  background-color: #FAFBFC;
}

.m0 {
  margin: 0;
}

.m0i {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0;
}

.mr0 {
  margin-right: 0;
}

.mr0i {
  margin-right: 0 !important;
}

.mb0 {
  margin-bottom: 0;
}

.ml0 {
  margin-left: 0;
}

.ml0i {
  margin-left: 0px !important;
}

.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx0 {
  margin-right: 0;
  margin-left: 0;
}

.m5 {
  margin: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb5i {
  margin-bottom: 5px !important;
}

.ml5 {
  margin-left: 5px;
}

.my5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mx5 {
  margin-right: 5px;
  margin-left: 5px;
}

.m12 {
  margin: 12px;
}

.mt12 {
  margin-top: 12px;
}

.mr12 {
  margin-right: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.ml12 {
  margin-left: 12px;
}

.my12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mx12 {
  margin-right: 12px;
  margin-left: 12px;
}


$margin-limit: 10;

@mixin margin-list {
  @for $i from 1 through $margin-limit {
    .m#{$i}0 {
      margin: #{$i}0px;
    }

    .mt#{$i}0 {
      margin-top: #{$i}0px;
    }

    .mr#{$i}0 {
      margin-right: #{$i}0px;
    }

    .mb#{$i}0 {
      margin-bottom: #{$i}0px;
    }

    .ml#{$i}0 {
      margin-left: #{$i}0px;
    }

    .my#{$i}0 {
      margin-top: #{$i}0px;
      margin-bottom: #{$i}0px;
    }

    .mx#{$i}0 {
      margin-right: #{$i}0px;
      margin-left: #{$i}0px;
    }

    .m#{$i}0i {
      margin: #{$i}0px;
    }

    .mt#{$i}0i {
      margin-top: #{$i}0px !important;
    }

    .mr#{$i}0i {
      margin-right: #{$i}0px !important;
    }

    .mb#{$i}0i {
      margin-bottom: #{$i}0px !important;
    }

    .ml#{$i}0i {
      margin-left: #{$i}0px !important;
    }

    .my#{$i}0i {
      margin-top: #{$i}0px !important;
      margin-bottom: #{$i}0px !important;
    }

    .mx#{$i}0i {
      margin-right: #{$i}0px !important;
      margin-left: #{$i}0px !important;
    }
  }
}

.m32p {
  margin: 32px;
}

.mt32 {
  margin-top: 32px;
}

.mr32 {
  margin-right: 32px;
}

.mb32 {
  margin-bottom: 32px;
}

.ml32 {
  margin-left: 32px;
}

.my32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mx32 {
  margin-right: 32px;
  margin-left: 32px;
}

@include margin-list;

.p0 {
  padding: 0px;
}

.p0i {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0px;
}

.pr0 {
  padding-right: 0px;
}

.pb0 {
  padding-bottom: 0px;
}

.pl0 {
  padding-left: 0px;
}

.pl0i {
  padding-left: 0px !important;
}

.py0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py0i {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px0 {
  padding-right: 0px;
  padding-left: 0px;
}

.p5 {
  padding: 5px;
}

.pt5 {
  padding-top: 5px;
}

.pr5 {
  padding-right: 5px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl5 {
  padding-left: 5px;
}

.py5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px5 {
  padding-right: 5px;
  padding-left: 5px;
}

.p12 {
  padding: 12px;
}

.pt12 {
  padding-top: 12px;
}

.pr12 {
  padding-right: 12px;
}

.pb12 {
  padding-bottom: 12px;
}

.pl12 {
  padding-left: 12px;
}

.py12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.px12 {
  padding-right: 12px;
  padding-left: 12px;
}

.p24 {
  padding: 24px;
}

.pt24 {
  padding-top: 24px;
}

.pr24 {
  padding-right: 24px;
}

.pb24 {
  padding-bottom: 24px;
}

.pl24 {
  padding-left: 24px;
}

.py24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.px24 {
  padding-right: 24px;
  padding-left: 24px;
}

$padding-limit: 10;

@mixin padding-list {
  @for $i from 1 through $padding-limit {
    .p#{$i}0 {
      padding: #{$i}0px;
    }

    .p#{$i}5 {
      padding: #{$i}5px;
    }

    .pt#{$i}0 {
      padding-top: #{$i}0px;
    }

    .pr#{$i}0 {
      padding-right: #{$i}0px;
    }

    .pb#{$i}0 {
      padding-bottom: #{$i}0px;
    }

    .pl#{$i}0 {
      padding-left: #{$i}0px;
    }

    .py#{$i}0 {
      padding-top: #{$i}0px;
      padding-bottom: #{$i}0px;
    }

    .py#{$i}5 {
      padding: #{$i}5px;
    }

    .px#{$i}0 {
      padding-right: #{$i}0px;
      padding-left: #{$i}0px;
    }

    .px#{$i}5 {
      padding: #{$i}5px;
    }

    .p#{$i}0i {
      padding: #{$i}0px !important;
    }

    .pt#{$i}0i {
      padding-top: #{$i}0px !important;
    }

    .pr#{$i}0i {
      padding-right: #{$i}0px !important;
    }

    .pb#{$i}0i {
      padding-bottom: #{$i}0px !important;
    }

    .pl#{$i}0i {
      padding-left: #{$i}0px !important;
    }

    .py#{$i}0i {
      padding-top: #{$i}0px !important;
      padding-bottom: #{$i}0px !important;
    }

    .px#{$i}0i {
      padding-right: #{$i}0px !important;
      padding-left: #{$i}0px !important;
    }
  }
}

@include padding-list;

.bold {
  font-weight: bold;
}

.b1 {
  font-weight: 100;
}

.b2 {
  font-weight: 200;
}

.b3 {
  font-weight: 300;
}

.b4 {
  font-weight: 400;
}

.b5 {
  font-weight: 500;
}

.b6 {
  font-weight: 600;
}

.b7 {
  font-weight: 700;
}

.b8 {
  font-weight: 800;
}

.b9 {
  font-weight: 900;
}

.h100 {
  height: 100%;
}

.w100,
.input-small {
  width: 100px;
}

.w50 {
  width: 50%;
}

.w100px {
  width: 100px;
}

.w140,
.input-med {
  width: 140px;
}

.w220,
.input-med-2 {
  width: 220px;
}

.w400,
.input-large {
  .ant-select-selector {
    width: 400px !important;
  }
}

.w-mc {
  width: max-content;
}

.overflow-a {
  overflow: auto;
}

.overflow-h {
  overflow: hidden;
}

$border-style: 1px solid var(--border-gray);

.error-border,
.error-border:focus,
.error-border:hover
.error-border:disabled {
  border-color: var(--secondary-red) !important;
  box-shadow: var(--secondary-red) !important;
}

.no-border {
  border: unset;
}

.no-border-i {
  border: unset !important;
}

.border {
  border: $border-style;
}

.border-t {
  border-top: $border-style;
}

.border-b {
  border-bottom: $border-style;
}

.border-l {
  border-left: $border-style;
}

.border-r {
  border-right: $border-style;
}

.border-x {
  border-top: $border-style;
  border-bottom: $border-style;
}

.border-y {
  border-left: $border-style;
  border-right: $border-style;
}

.default-border-radius,
.default-br,
.bdr-r {
  border-radius: var(--default-radius);
}

.secondary-border-radius,
.secondary-br {
  border-radius: var(--secondary-radius);
}

.border-gray-2 {
  border: $border-style;
  border-width: 2px;
  border-radius: var(--default-radius);
}

.border-gray-1 {
  border: $border-style;
  border-width: 1px;
  border-radius: 5px;
}

.box-shadow {
  box-shadow: 0px 0px 8px 0px #00000026;
}

.autoH {
  height: auto;
}

.warning,
.warning-svg svg {
  color: var(--secondary-orange);
}

.danger,
.danger-svg svg {
  color: var(--secondary-red);
}

.success,
.success-svg svg {
  color: var(--secondary-green);
}

.primary,
.primary-svg svg {
  color: var(--blue);
}

.ant-tag {
  border: none;
}

.light-blue {
  color: rgba(24, 144, 255, 1);
}

.light-blue-hover:hover {
  background-color: rgba(24, 144, 255, 0.1);
}

.no-hover:hover {
  background-color: unset;
}

.no-active:active {
  background-color: unset;
}

.no-focus:focus {
  background-color: unset;
}

.no-style-btn,
.no-style-btn:hover {
  padding: 0;
  border: unset;
  background: transparent;
  color: inherit;
  height: auto;
}

.gray-btn {
  background-color: #F4F6F8;
  color: var(--gray);
  border: unset;
}

.ant-btn-primary {
  background-color: var(--uc-blue);
  border-color: var(--uc-blue);
}

.ant-btn-background-ghost.ant-btn-primary {
  color: var(--uc-blue);
  border-color: var(--uc-blue);
}

.ant-btn {
  border-radius: var(--default-radius);
}

.no-confirm-modal-buttons {
  .ant-modal-confirm-btns {
    display: none;
  }
}

.ant-form-item-has-error {
  .ant-input-affix-wrapper {
    border-color: var(--secondary-red) !important;
  }

  .ant-form-item-explain-error {
    color: var(--secondary-red);
  }
}

.hide {
  display: none;
}

.show-borders-for-all,
.show-borders-for-all *:not(b) {
  border: 1px solid gray;
  min-width: 500px;
  max-width: 1500px;
  padding: 10px;
  margin: 10px;
}

.table-cell-no-padding {
  tbody .ant-table-cell {
    padding: 0;
  }
}

.table-header-cell-center {
  thead .ant-table-cell {
    text-align: center;
  }
}

.ant-input,
.ant-select,
.ant-select-selection-search-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
  border-radius: var(--default-radius);
}

body {

  tbody .ant-table-cell,
  th.ant-table-cell {
    padding: 10px;
  }
}

.primary-link {
  color: var(--uc-blue);
}

.link-color {
  color: var(--primary-blue-6);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  clear: both;
}

@mixin width-list {
  @for $i from 1 through 10 {
    .w#{$i}0 {
      width: #{$i}0#{"%"};
      max-width: #{$i}0#{"%"};
    }

    .w#{$i}0i {
      width: #{$i}0#{"% !important"};
      max-width: #{$i}0#{"% !important"};
    }
  }
}

@include width-list;

.compact {
  .ant-input-compact-last-item {
    border-left: none;
  }
}

@mixin opacity-list {
  @for $i from 1 through 9 {
    .op#{$i} {
      opacity: #{"0."}#{$i};
    }
  }
}

@include opacity-list;


.relative {
  position: relative;
}

.w400 {
  width: 400px;
}

.absolute {
  position: absolute;
}

.header-reversed {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
}

.breakword {
  white-space: break-spaces;
  word-break: break-word;
}

.of-hidden {
  overflow: hidden;
}

.of-auto {
  overflow: auto;
}

.of-x-auto {
  overflow-x: auto;
}

.of-y-auto {
  overflow-y: auto;
}

.blend-dark {
  mix-blend-mode: darken;
}

.blend-bright {
  mix-blend-mode: color-burn;
}

.mw400 {
  min-width: 400px;
}

.text-primary-green {
  color: var(--primary-green) !important;
}

.text-secondary-yellow {
  color: var(--secondary-yellow) !important;
}

.bg-green {
  background-color: var(--tertiary-green) !important;
}

.bg-yellow {
  background-color: var(--light-yellow) !important;
}

.bg-uc-blue {
  background-color: var(--uc-blue);
}

.mw100 {
  max-width: 100%;
}

.minW80 {
  min-width: 80px;
}

$bg-limit: 8;

@mixin bg-list {
  @for $i from 1 through $bg-limit {
    .bg-gray#{$i} {
      background-color: var(--gray-scale-#{$i});
    }
  }
}

@include bg-list;

@mixin z-index-list {
  @for $i from 1 through 10 {
    .zi#{$i} {
      z-index: #{$i};
    }
  }
}

@include z-index-list;

.require-mark {
  @mixin require-mark {
    content: '*';
    color: var(--secondary-red);
  }

  &::after {
    @include require-mark;
    margin-left: 5px;
  }

  &-no-margin {
    &::after {
      @include require-mark;
      margin: 0px;
    }
  }
}

.anchor-hover {
  &:hover {
    color: var(--primary-blue-6);
  }
}

.invisible {
  visibility: hidden;
}

.required-label {
  position: relative;
  color: var(--secondary-gray);
  padding-bottom: 4px;
}

.required-label::after {
  content: "*";
    display: inline-block;
    margin-right: 4px;
    margin-left: 2px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
}

.text-overflow-wrap {
  overflow-wrap: anywhere;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pagebreak {
  page-break-before: always;
}

.pre-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}