.CgmGoalCardComponent {  
  &__cgm-goal {
    width: 120px;
  }

  .meeting-status {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-size: 12px;
      color: #c4c2c2;
    }
  }
  
  .tooltip-component.ant-space {
    gap: 2px !important;
  }
}
