.cgm-vital-container {
  position: relative;

  .cgm-vital-container__header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    padding-bottom: 20px;
  }
}

.clarity-dexcom-com-link {
  background-color: #E9F1FA;
  color: #000;
  border-radius: 4px;
  padding: 4px 7px;
}