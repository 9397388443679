.physical-activity__row {
  flex-flow: nowrap;
  width: max-content;
}

.physical-activity__type {
  width: 295px;
}

.physical-activity__time-period {
  width: 270px;
}

.physical-activity__intensity {
  display: flex;
  gap: 5px;
  align-items: baseline;
}