.cgm-consent-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .cgm-consent-form-container__banner {
    margin-bottom: 24px;
  }

  .cgm-consent-form-container__consent-form {
    flex: 1;
  }
}