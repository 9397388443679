.cgm-compliance-alert-tooltip {
  .ant-popover-content {
    width: 330px;
  }

  .ant-popover-inner,
  .ant-popover-arrow-content::before {
    background-color: #595959;
  }

  .ant-popover-inner-content {
    color: #FFFFFF;
  }

  ol {
    padding-left: 20px;
    padding-top: 4px;
    line-height: 24px;
  }
}