.CgmVitalSummaryComponent {
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
  gap: 10px;
  flex-wrap: wrap;

  .tag {
    padding: 0px;
  }

  .solid-dot-small {
    width: 5px;
    height: 5px;
  }

  .solid-dot {
    margin-right: 0px;
  }

  // TAR level 2: Very High
  .solid-dot-error {
    background-color: #CE7A35;
  }

  // TAR level 1: High
  .solid-dot-warning {
    background-color: #DDA73B;
  }

  // TBR level 1: Low
  .solid-dot-processing {
    background-color: #BA282E;
  }

  // TBR level 2: Very Low
  .solid-dot-default {
    background-color: #742F32;
  }

  .CgmStatisticsComponent, .CgmBarChartComponent {
    min-width: 530px;
    flex: 1;
    justify-content: space-between;
  }

  &__summary-box {
    display: grid;
    min-width: 80px;
  }

  &__legend {
    color: var(--Grayscale-3, #6B7178);
    font-family: "Noto Sans";
    font-size: 14px;
    margin-right: 5px;
    width: 150px;
    max-height: 115px;
    display: flex;
  }

  &__legend-tooltip {
    color: white;
    font-family: "Noto Sans";
    font-size: 12px;
  }

  &__tir-title {
    color: black;
    font-weight: 600;
  }

  &__tir-goal {
    color: black;
    font-weight: 400;
  }

  &__tir-value {
    color: var(--Grayscale-1, #2D3238);
    font-size: 16px;
    font-weight: 700;
  }

  &__tooltip {
    justify-content: space-around;
    margin-right: 50px;
  }
}
