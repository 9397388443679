.AppInfoComponent {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding: 0px;
  }
  
  .title-content-text-title {
    font-size: 12px;
    color: #6B7178;
  }

  .tag.tag-error {
    align-items: flex-start;
  }

  .text-gray-scale-2 .ant-collapse-header {
    color: #6B7178;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  &__app-authority {
    margin-left: -10px;
  }

  .ant-col-7 {
    align-self: center;
    text-decoration: underline;
  }
  .ant-col-8 {
    align-self: center;
  }
}
