@use '../WorklistDrawerComponent/WorklistDrawerComponent.scss' as drawerStyle;

.follow-up-schedule {
  .create-visit-form__form-section > .fixedChildComponentWrapper {
    padding-left: 0px;
    margin: 0px;
  }

  .create-visit-form__section {
    padding-left: 0px;
  }
}

@media only screen and (max-width: drawerStyle.$min-width) {
  .follow-up-schedule {
    max-width: 100%;
  }
}