.meal-sleep-routine-table {
  th.ant-table-cell {
    text-align: center;
  }

  tr.ant-table-row {
    vertical-align: top;
  }

  tbody .ant-table-cell {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .ant-table-cell .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-input {
    border: unset;
  }

  [data-name*="mealAndSleepRoutines"] .ant-select-selector {
    width: 108px;
  }
}

.meal-sleep-routine-table.isEditable {
  tbody .ant-table-cell {
    padding-top: 8px;
    padding-right: 2px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}