.old-nutrition-component {
  padding-top: 20px;
  //border: 1px solid #E7EAED;
  //border-radius: var(--secondary-radius);

  .text-title {
    color: #6B7178;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .text-content {
    color: #2D3238;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .text-link {
    color: #6B7178;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-color: #6B7178;
  }

  .ant-select-single .ant-select-selector {
    width: 100%;
    padding: 0 11px;
  }

  .ant-select-selection-item {
    white-space: break-spaces;
  }

  .ant-select-item-option-content {
    white-space: break-spaces;
  }
}