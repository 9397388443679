.outstanding-component {
  display: flex;
  flex-direction: column;
  height: 100%;

  .bubble-tab-component > .ant-tabs-nav {
    padding: 0 20px;
  }

  & > .tab-fixed-header {
    & > .ant-tabs-content-holder {
      margin-top: 60px;
    }
  }

  .ant-tabs-tabpane-active {
    padding: 20px;
  }
}
