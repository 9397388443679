.CgmReportPreviewDrawerContainer {
  .ant-drawer-content-wrapper {
    right: 400px;
  }
  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    background-color: var(--gray-scale-4);
  }

  .CgmReportPreviewComponent {
    .page {
      min-height: 1056px;
    }
  }
}

.CgmReportPreviewCareNoteContainer {
 .ant-drawer-mask {
  display: none;
 }

 .ant-drawer-content {
  background-color: var(--gray-scale-7);
 }
}

.cgm-clarity-tooltips {
  background-color: #FFFAE6;

  .clarity-tooltips-circle {
    color: #EF973B;
  }
}
