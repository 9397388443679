.audio-bar,
.audio-bar-play {
  border-radius: 12px;
  border-top-left-radius: 4px;
  border: none;
  height: 48px;
  max-width: 150px;
  box-shadow: none;
  cursor: default;
  user-select: none;
  transition: background-color 0.3s ease;
}

.audio-bar {
  background-color: #1890FF;
}

.audio-bar:hover,
.audio-bar:focus {
  background-color: #1890FF;
  box-shadow: none;
  border: none;
  outline: none;
}

.audio-bar-play {
  background-color: #096DD9;
}

.audio-bar-play:hover,
.audio-bar-play:focus,
.audio-bar-play:active {
  background-color: #096DD9;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: default;
  user-select: none;
}

.play-icon {
  font-size: 18px;
  color: #1890FF;
  background-color: white;
  border-radius: 50%;
  height: 27px;
  min-width: 27px;
}

.wave-icon {
  transform: rotate(90deg);
  transform-origin: center;
}
