.CgmGoalThresholdComponent {  
  &__cgm-threshold-title {
    font-size: 12px;
    margin-bottom: 3px;
    color: #6B7178;
    align-items: center;
    display: flex;
    gap: 1px;
    min-width: 110px;
  }

  &__cgm-unit {
    font-size: 10px;
    color: var(--Grayscale-2, #6B7178);
  }
}