.CgmPotentialTextAreaComponent {
  position: relative;
  border: 1px solid var(--border-gray);
  padding: 12px 20px;

  .CgmPotentialTagComponent {
    margin-bottom: 10px;
  }

  .cgmPotential {
    border: none;
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }
}
